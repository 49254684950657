import React from 'react';
import './Header.css';

const Header = () => {
    return ( 
        < div className = 'mt4 mb5 white fw6 bold tc ttu' >
       <header><h1> Tides </h1> </header> 
       <p>scroll down to view tide data</p>
        </div >
    );
}

export default Header;